import React, { useMemo, useState } from 'react';
import { AlertTriangle, Edit3 } from 'react-feather';
import { formatMoney, SupplierJobApiResponse } from '@dill/dill-shared';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import moment from 'moment';

import emptyListImg from '../../../../assets/images/emptyList.png';

import AppCustomDropdown from '../../../general/AppCustomDropdown/AppCustomDropdown';
import { AppNewSupplierJobModal } from '../../../general/AppNewSupplierJobModal/AppNewSupplierJobModal';
import { AppButton } from '../../../general/AppButton/AppButton';
import AppCreateReleaseModal from '../../../general/AppCreateReleaseModal/AppCreateReleaseModal';
import CreateSignatureModal from '../../lienWaivers/supplier/CreateSignatureModal/CreateSignatureModal';
import AppEditFirstDeliveryDateModal from '../../../general/AppEditFirstDeliveryDateModal';
import { Ellipsis } from 'react-css-spinners';
import { CheckCircle, CircleWavyCheck, Warning, WarningCircle } from 'phosphor-react';
import { AppDropDown } from '../../../general/AppDropDown/AppDropDown';
import { components, type ControlProps, type StylesConfig } from 'react-select';
import { FormOptionItem } from '../../../../utils/interfaces/generalInterfaces';
import { useAppDispatch } from '../../../../redux/store';
import { updateSupplierJob } from '../../../../redux/services/supplierJobsService';
import { AppVirtualizedList } from '../../../general/AppVirtualizedList/AppVirtualizedList';
import { useWindowActualWidth } from '../../../../utils/domTools';

const CustomOption = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="flex flex-row gap-3 font-semibold">
        <span>{props.data.icon}</span>
        <span>{props.data.label}</span>
      </div>
    </components.Option>
  );
};

const CustomControl = ({ children, ...props }: { children: React.ReactNode } & ControlProps) => {
  const { selectProps } = props;

  const selectedProps = selectProps && (selectProps.value as Record<string, any>);
  let activeValue = '';

  if (selectedProps && selectedProps?.value) {
    activeValue = selectedProps.value;
  }

  const activeIcon =
    activeValue === 'done' ? <CheckCircle fontWeight={600} size={14} /> : <Warning size={14} />;

  return (
    <components.Control {...props}>
      <span className="text-white font-semibold align-center">{activeIcon}</span>
      {children}
    </components.Control>
  );
};

const SupplierJobsTable = ({
  supplierJobs,
  setSupplierJobs,
  infiniteRef,
  hasNextPage = false,
  loading = false,
  onSortChange = () => {},
  onReload = () => {},
  sortOptions,
}: {
  supplierJobs: SupplierJobApiResponse[];
  setSupplierJobs?: (supplierJobs: SupplierJobApiResponse[]) => void;
  infiniteRef?: any;
  hasNextPage?: boolean;
  loading?: boolean;
  sortOptions: { sortOrder: 'asc' | 'desc'; sortBy: string };
  onSortChange: (props: { sortOrder: 'asc' | 'desc'; sortBy: string }) => void;
  onReload: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [selectedSupplierJob, setSelectedSupplierJob] = useState({} as SupplierJobApiResponse);
  const [editJob, setEditJob] = useState<boolean>(false);
  const [editFirstDeliveryDate, setEditFirstDeliveryDate] = useState<boolean>(false);
  const [softFirstDeliveryDate, setSoftFirstDeliveryDate] = useState<Date | null>(null);
  const [openCreateReleaseModal, setOpenCreateReleaseModal] = useState<boolean>(false);
  const [openDropdown, setOpenDropDown] = useState('');
  const [openLienDropdown, setOpenLienDropdown] = useState('');
  const [isCreateSignatureModalOpen, setIsCreateSignatureModalOpen] = useState(false);
  const [signature, setSignature] = useState('');
  const [dateModalType, setDateModalType] = useState<
    'firstDeliveryDate' | 'lastDeliveryDate' | 'noticeOfCompletionDate'
  >('firstDeliveryDate');
  const width = useWindowActualWidth();

  const columns = useMemo(() => {
    return [
      { id: 'supplierJob', name: 'Job', width: 130, cellType: 'VIEW_TEXT' },
      { id: 'contractor', name: 'Contractor', width: 86, cellType: 'VIEW_TEXT' },
      { id: 'location', name: 'Location', width: 110, cellType: 'VIEW_TEXT' },
      { id: 'owner', name: 'Owner', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'lender', name: 'Lender', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'apn', name: 'Parcel Number', width: 110, cellType: 'VIEW_TEXT' },
      { id: 'firstDeliveryDate', name: 'First Delivery Date', width: 130, cellType: 'VIEW_TEXT' },
      { id: 'lastDeliveryDate', name: 'Last Delivery Date', width: 130, cellType: 'VIEW_TEXT' },
      {
        id: 'noticeOfCompletionDate',
        name: 'Notice of Completion Date',
        width: 150,
        cellType: 'VIEW_TEXT',
      },
      { id: 'prelien', name: 'Pre-Lien', width: 125, cellType: 'VIEW_TEXT' },
      { id: 'prelienDeadline', name: 'Pre-Lien Deadline', width: 135, cellType: 'VIEW_TEXT' },
      { id: 'lienDeadline', name: 'Lien Deadline', width: 125, cellType: 'VIEW_TEXT' },
      { id: 'openAR', name: 'Open AR', width: 115, cellType: 'VIEW_TEXT' },
      { id: 'action', name: '', width: 250, cellType: 'VIEW_TEXT' },
    ];
  }, []);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  const handleAddSignature = (newSignature: string, isManual: boolean) => {
    setSignature(newSignature);
    setIsCreateSignatureModalOpen(false);
  };

  // Our timestamps are in UTC without timezone info. We need to convert them to PST
  const convertDateToPST = (date: Date) => {
    const dtString = moment.utc(date).format('YYYY-MM-DD');
    return new Date(`${dtString}T12:00:00`); // set to 12:00 PM
  };

  const getDeliveryDate = (
    softDeliveryDt: Date | null | undefined,
    invoiceDeliveryDate: Date | null | undefined
  ) => {
    let dt = null;

    if (invoiceDeliveryDate) {
      dt = convertDateToPST(invoiceDeliveryDate);
    } else if (softDeliveryDt) {
      dt = convertDateToPST(softDeliveryDt);
    }

    return dt;
  };

  const getDeliveryDateString = (
    softDeliveryDt: Date | null | undefined,
    invoiceDeliveryDate: Date | undefined
  ) => {
    const dt = getDeliveryDate(softDeliveryDt, invoiceDeliveryDate);

    return dt
      ? dt.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : '';
  };

  const customStyles: StylesConfig<FormOptionItem, false> = {
    control: (provided: any, selectProps: any) => {
      const backgroundColor =
        selectProps?.selectProps?.value?.value === 'done' ? '#32D583' : '#F04438';

      return {
        ...provided,
        borderRadius: '34px !important',
        minHeight: '42px',
        borderColor: 'none',
        marginBottom: '4px',
        backgroundColor: backgroundColor,
        fontWeight: 600,
        paddingLeft: '8px',
        textAlign: 'center',
        boxShadow: 'none',
      };
    },
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: '#fff',
      width: '24px',
      height: '24px',
      paddingLeft: '0px',
      alignItems: 'center',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#fff',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      color: '#fff',
      paddingLeft: '0px',
      paddingRight: '0px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      color: 'black',
      opacity: state.isSelected ? 0.5 : 1,
      cursor: 'pointer',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      paddingLeft: '0px',
    }),
  };

  const customSelectComps = {
    Option: CustomOption,
    Control: CustomControl,
  };

  const getPreLienDeadlineDate = (date: Date | null | undefined) => {
    return date ? moment(date).add(20, 'days').toDate() : null;
  };

  const updateSupplierJobLienStatus = async (
    supplierJobId: string,
    supplierJobDetails: SupplierJobApiResponse
  ) => {
    return dispatch(
      updateSupplierJob({
        supplierJobId: supplierJobId,
        supplierJobDetails: supplierJobDetails,
      })
    );
  };

  const getLienDeadlineDate = (
    state: string,
    lastDeliveryDate: Date | null | undefined,
    noticeOfCompletionDate: Date | null | undefined
  ) => {
    if (!lastDeliveryDate) {
      return null; // Return empty if last delivery date is not provided
    }

    let preLienDate: Date;

    if (noticeOfCompletionDate) {
      // If Notice of Completion Date exists
      preLienDate = new Date(noticeOfCompletionDate);
      preLienDate.setDate(preLienDate.getDate() + (state === 'CA' ? 30 : 90));
    } else {
      // If no Notice of Completion Date exists
      preLienDate = new Date(lastDeliveryDate);
      preLienDate.setDate(preLienDate.getDate() + (state === 'CA' ? 90 : 180));
    }

    return preLienDate;
  };

  const renderRow = (item: { id: string; content: React.ReactNode }) => {
    const index = Number(item.id);
    const key = index + 'supplier-job';
    const supplierJob = supplierJobs[index];

    const firstJobInvoice =
      supplierJob?.invoices && supplierJob?.invoices.length > 0 ? supplierJob?.invoices[0] : null;
    const lastJobInvoice =
      supplierJob?.invoices && supplierJob?.invoices.length > 0
        ? supplierJob?.invoices[supplierJob?.invoices.length - 1]
        : null;

    const firstInvoiceCreationDate = firstJobInvoice?.createdAt;
    const lastInvoiceCreationDate = lastJobInvoice?.createdAt;

    const firstDeliveryDate = getDeliveryDate(
      firstInvoiceCreationDate,
      supplierJob.firstDeliveryDate
    );

    const lastDeliveryDate = getDeliveryDate(lastInvoiceCreationDate, supplierJob.lastDeliveryDate);

    const getSupplierJobState = (state: string) => {
      const regex = /ca|california|ut|utah/gi;
      const match = state.match(regex);
      return match ? match[0].toUpperCase() : '';
    };

    const lienDateDeadlineDate = getLienDeadlineDate(
      getSupplierJobState(supplierJob.jobState),
      lastDeliveryDate,
      supplierJob.noticeOfCompletionDate
    );
    const isLienDateDeadlinePassed = lienDateDeadlineDate
      ? moment().isAfter(lienDateDeadlineDate)
      : false;

    const lienDeadlineDateString = lienDateDeadlineDate
      ? lienDateDeadlineDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : '';

    const processedNoticeOfCompletionDate =
      supplierJob.noticeOfCompletionDate && typeof supplierJob.noticeOfCompletionDate === 'string'
        ? new Date(supplierJob.noticeOfCompletionDate)
        : supplierJob.noticeOfCompletionDate;

    const preLienDateDeadlineDate = getPreLienDeadlineDate(firstDeliveryDate);
    const isPreLienDateDeadlinePassed = preLienDateDeadlineDate
      ? moment().isAfter(preLienDateDeadlineDate)
      : false;
    const preLienDateDeadlineDateString =
      preLienDateDeadlineDate &&
      preLienDateDeadlineDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });

    const finalPreLienStatus = supplierJob.preLienStatus
      ? supplierJob.preLienStatus
      : supplierJob.preLienNotice
      ? 'done'
      : 'not-yet';

    const totalOpenAR =
      supplierJob?.invoices?.reduce((acc, invoice) => {
        return acc + (typeof invoice.balance === 'number' ? invoice.balance : 0);
      }, 0) || 0; // Added initial value of 0

    return (
      <div key={supplierJob.id} className="w-full flex-row">
        <div className="w-full flex items-center justify-between px-4">
          {columns.map((column) => {
            return (
              <div
                key={column.id + supplierJob.id}
                style={{ minWidth: 100, flex: column.width / totalWidth }}
                className={`flex flex-col py-3 px-1`}>
                <div className="">
                  {column.id === 'supplierJob' && (
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-col w-4/5">
                        <p
                          data-tooltip-id={`job-tooltip-${supplierJob.id}`}
                          className="text-sm capitalize overflow-hidden text-ellipsis whitespace-nowrap font-bold">
                          {supplierJob.name}
                        </p>
                        <ReactTooltip
                          id={`job-tooltip-${supplierJob.id}`}
                          place="top"
                          variant="dark"
                          opacity={'100%'}
                          style={{
                            background: '#222',
                            zIndex: 99999,
                          }}>
                          <div className="flex flex-col">
                            <span className="text-xs capitalize">{supplierJob.name || ''}</span>
                            <span className="text-xs capitalize overflow-hidden text-ellipsis">
                              {supplierJob.jobNumber || ''}
                            </span>
                          </div>
                        </ReactTooltip>
                      </div>

                      {supplierJob.constructionMonitorPermitId && (
                        <>
                          <div className="flex flex-col items-center pt-1">
                            <CircleWavyCheck color="#0BA5EC" />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {column.id === 'contractor' && (
                    <div className="w-full flex flex-col">
                      <p
                        data-tooltip-id={`buyer-tooltip-contractor-${supplierJob.id}`}
                        className="text-sm capitalize overflow-hidden text-ellipsis whitespace-nowrap">
                        {supplierJob.buyer?.name}
                      </p>
                      <ReactTooltip
                        id={`buyer-tooltip-contractor-${supplierJob.id}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          zIndex: 99999,
                        }}>
                        <div className="flex flex-col">
                          <span className="text-xs capitalize">
                            {supplierJob.buyer?.name || ''}
                          </span>
                          <span className="text-xs capitalize overflow-hidden text-ellipsis">
                            {`${supplierJob.buyer?.address || ''}`}
                          </span>
                          <span className="text-xs capitalize overflow-hidden text-ellipsis">
                            {`${supplierJob.buyer?.city || ''}, ${supplierJob.buyer?.state || ''} ${
                              supplierJob.buyer?.zipCode || ''
                            }`}
                          </span>
                        </div>
                      </ReactTooltip>
                    </div>
                  )}
                  {column.id === 'location' && (
                    <div className="w-full flex flex-col">
                      <p
                        data-tooltip-id={`location-tooltip-${supplierJob.id}`}
                        className="text-sm capitalize overflow-hidden text-ellipsis whitespace-nowrap">
                        {supplierJob.jobAddress}
                      </p>
                      <ReactTooltip
                        id={`location-tooltip-${supplierJob.id}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          zIndex: 99999,
                        }}>
                        <div className="flex flex-col">
                          <span className="text-xs capitalize">{supplierJob.jobAddress || ''}</span>
                          <span className="text-xs capitalize">{`${supplierJob.jobCity || ''}, ${
                            supplierJob.jobState || ''
                          } ${supplierJob.jobZipCode || ''}`}</span>
                        </div>
                      </ReactTooltip>
                    </div>
                  )}
                  {column.id === 'owner' && (
                    <div className="w-full flex flex-col">
                      <p
                        data-tooltip-id={`owner-tooltip-${supplierJob.id}`}
                        className="text-sm capitalize overflow-hidden text-ellipsis whitespace-nowrap">
                        {supplierJob.owner}
                      </p>
                      <ReactTooltip
                        id={`owner-tooltip-${supplierJob.id}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          zIndex: 99999,
                        }}>
                        <div className="flex flex-col">
                          <span className="text-xs capitalize">{supplierJob.owner}</span>
                          <span className="text-xs capitalize">
                            {supplierJob.ownerAddress || ''}
                          </span>
                          <span className="text-xs capitalize">{`${supplierJob.ownerCity || ''}, ${
                            supplierJob.ownerState || ''
                          } ${supplierJob.ownerZipCode || ''}`}</span>
                        </div>
                      </ReactTooltip>
                    </div>
                  )}
                  {column.id === 'lender' && (
                    <div className="w-full flex flex-col">
                      <p
                        data-tooltip-id={`lender-tooltip-${supplierJob.id}`}
                        className="text-sm capitalize overflow-hidden text-ellipsis">
                        {supplierJob?.lender || ''}
                      </p>
                      <ReactTooltip
                        id={`lender-tooltip-${supplierJob.id}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          zIndex: 99999,
                        }}>
                        <div className="flex flex-col">
                          <span className="text-xs capitalize">{supplierJob?.lender || ''}</span>
                          <span className="text-xs capitalize">{`${
                            supplierJob?.lenderAddress || ''
                          }`}</span>
                          <span className="text-xs capitalize">{`${
                            supplierJob?.lenderCity || ''
                          }, ${supplierJob?.lenderState || ''} ${
                            supplierJob?.lenderZipCode || ''
                          }`}</span>
                        </div>
                      </ReactTooltip>
                    </div>
                  )}
                  {column.id === 'apn' && (
                    <div className="w-full flex flex-col">
                      <p className="text-sm capitalize overflow-hidden text-ellipsis">
                        {supplierJob?.apn || ''}
                      </p>
                    </div>
                  )}
                  {column.id === 'firstDeliveryDate' && (
                    <div className="w-full flex flex-col">
                      <div className="flex flex-row gap-2 items-center">
                        {firstDeliveryDate && !supplierJob.firstDeliveryDate && (
                          <AlertTriangle
                            data-tooltip-id="soft-delivery-date-tooltip"
                            size={16}
                            color="#FDB022"
                          />
                        )}
                        {
                          <>
                            {getDeliveryDateString(
                              firstInvoiceCreationDate,
                              supplierJob.firstDeliveryDate
                            ) || ''}
                          </>
                        }
                        <button
                          onClick={() => {
                            const firstDeliveryDt = getDeliveryDate(
                              firstInvoiceCreationDate,
                              supplierJob.firstDeliveryDate
                            );

                            if (firstDeliveryDt) {
                              setSoftFirstDeliveryDate(firstDeliveryDt);
                            }
                            setDateModalType('firstDeliveryDate');
                            setSelectedSupplierJob(supplierJob);
                            setEditFirstDeliveryDate(true);
                          }}>
                          <Edit3 size={16} />
                        </button>
                      </div>
                    </div>
                  )}
                  {column.id === 'lastDeliveryDate' && (
                    <div className="w-full flex flex-col">
                      <div className="flex flex-row gap-2 items-center">
                        {getDeliveryDate(lastInvoiceCreationDate, supplierJob.lastDeliveryDate) &&
                          !supplierJob.lastDeliveryDate && (
                            <AlertTriangle
                              data-tooltip-id="soft-delivery-date-tooltip"
                              size={16}
                              color="#FDB022"
                            />
                          )}
                        {getDeliveryDateString(
                          lastInvoiceCreationDate,
                          supplierJob.lastDeliveryDate
                        ) || ''}
                        <button
                          onClick={() => {
                            const lastDeliveryDt = getDeliveryDate(
                              lastInvoiceCreationDate,
                              supplierJob.lastDeliveryDate
                            );
                            if (lastDeliveryDt) {
                              setSoftFirstDeliveryDate(lastDeliveryDt);
                            }
                            setDateModalType('lastDeliveryDate');
                            setSelectedSupplierJob(supplierJob);
                            setEditFirstDeliveryDate(true);
                          }}>
                          <Edit3 size={16} />
                        </button>
                      </div>
                    </div>
                  )}
                  {column.id === 'noticeOfCompletionDate' && (
                    <div className="w-full flex flex-col">
                      <div className="flex flex-row gap-2 items-center">
                        {processedNoticeOfCompletionDate &&
                          processedNoticeOfCompletionDate.toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })}
                        <button
                          onClick={() => {
                            console.log(supplierJob?.noticeOfCompletionDate);
                            if (supplierJob.noticeOfCompletionDate) {
                              setSoftFirstDeliveryDate(supplierJob.noticeOfCompletionDate);
                            }
                            setDateModalType('noticeOfCompletionDate');
                            setSelectedSupplierJob(supplierJob);
                            setEditFirstDeliveryDate(true);
                          }}>
                          <Edit3 size={16} />
                        </button>
                      </div>
                    </div>
                  )}
                  {column.id === 'prelien' && (
                    <div className="w-full flex flex-col max-w-[100px]">
                      <AppDropDown
                        isRequired
                        isRequiredIconDisabled={true}
                        isSearchable={false}
                        items={[
                          {
                            value: 'done',
                            label: 'Done',
                            icon: <CheckCircle size={16} />,
                          },
                          {
                            value: 'not-yet',
                            label: 'Not Yet',
                            icon: <Warning size={16} />,
                          },
                        ]}
                        onSelectChange={async (selected: any) => {
                          if (setSupplierJobs) {
                            const updatedSupplierJobResult = await updateSupplierJobLienStatus(
                              supplierJob.id,
                              {
                                ...supplierJob,
                                preLienStatus: selected.value,
                                invoices: undefined,
                                preLienNotice: undefined,
                              }
                            );

                            // check if the update was successful
                            // if so, update the supplier job in the list
                            if (
                              updatedSupplierJobResult.type ===
                              'supplierJobs/updateSupplierJob/fulfilled'
                            ) {
                              const updatedSupplierJob = {
                                ...supplierJob,
                                preLienStatus: selected.value,
                              };
                              const updatedSupplierJobs = supplierJobs.map((job) => {
                                if (job.id === updatedSupplierJob.id) {
                                  return updatedSupplierJob;
                                }
                                return job;
                              });
                              setSupplierJobs(updatedSupplierJobs);
                            }
                          }
                        }}
                        value={finalPreLienStatus}
                        isDisabled={false}
                        customStyles={customStyles}
                        customSelectComponents={customSelectComps}
                      />
                    </div>
                  )}
                  {column.id === 'prelienDeadline' && (
                    <div
                      className={` flex flex-col w-full ${
                        lienDateDeadlineDate && isPreLienDateDeadlinePassed ? 'text-red-500' : ''
                      }`}>
                      <div className="flex flex-row align-center gap-1 font-semibold">
                        <div className="flex flex-col justify-center">
                          {lienDateDeadlineDate && isPreLienDateDeadlinePassed ? (
                            <WarningCircle
                              data-tooltip-id={`prelienDeadline-tooltip-${supplierJob.id}`}
                              size={16}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        {preLienDateDeadlineDateString}
                      </div>
                      <ReactTooltip
                        id={`prelienDeadline-tooltip-${supplierJob.id}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          zIndex: 99999,
                        }}>
                        <div className="flex flex-col">
                          <span className="text-xs capitalize">Overdue</span>
                        </div>
                      </ReactTooltip>
                    </div>
                  )}
                  {column.id === 'lienDeadline' && (
                    <div
                      className={` flex flex-col w-full ${
                        lienDateDeadlineDate && isLienDateDeadlinePassed && totalOpenAR > 0
                          ? 'text-red-500'
                          : 'GREY_500'
                      }`}>
                      <div className="flex flex-row align-center gap-1 font-semibold">
                        <div className="flex flex-col justify-center">
                          {lienDateDeadlineDate && isLienDateDeadlinePassed && totalOpenAR > 0 ? (
                            <WarningCircle
                              data-tooltip-id={`lienDeadline-tooltip-${supplierJob.id}`}
                              size={16}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        {lienDeadlineDateString}
                      </div>
                      <ReactTooltip
                        id={`lienDeadline-tooltip-${supplierJob.id}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          background: '#222',
                          zIndex: 99999,
                        }}>
                        <div className="flex flex-col">
                          <span className="text-xs capitalize">Overdue</span>
                        </div>
                      </ReactTooltip>
                    </div>
                  )}
                  {column.id === 'openAR' && (
                    <div className=" flex flex-col w-full">
                      <div className="flex flex-row align-center gap-1 GREY_500">
                        <div className="flex flex-col justify-center">
                          {totalOpenAR > 0 ? formatMoney(totalOpenAR, 2) : ''}
                        </div>
                      </div>
                    </div>
                  )}
                  {column.id === 'action' && (
                    <div className="flex w-[200px]">
                      <AppCustomDropdown
                        inputFieldStyles={{ width: '35px', marginLeft: '4px' }}
                        onSelect={(val) => {
                          setSelectedSupplierJob(supplierJob);
                          if (val === 'edit') {
                            setEditJob(true);
                          }
                        }}
                        options={[
                          {
                            value: 'edit',
                            label: 'Edit & Verify',
                            icon: <Edit3 size={16} />,
                          },
                        ]}
                        width="170px"
                        isDisabled={status === 'Inactive'}
                        open={openDropdown === supplierJob.id}
                        onOpen={() => setOpenDropDown(supplierJob.id)}
                        onClose={() => setOpenDropDown('')}
                      />
                      <AppButton
                        buttonStyles={{ width: '100%', marginLeft: '8px', height: '35px' }}
                        text="Create Lien Release"
                        type="PRIMARY"
                        onClick={() => {
                          setSelectedSupplierJob(supplierJob);
                          setOpenCreateReleaseModal(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {index === supplierJobs.length - 2 && hasNextPage && !loading && (
          <div ref={infiniteRef} className="w-full flex items-center justify-center ">
            <Ellipsis color="#3762FB" size={70} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full h-5/6 flex flex-col border rounded-lg overflow-x-scroll overflow-y-hidden mt-4">
      <AppNewSupplierJobModal
        open={editJob}
        editMode={true}
        selectedSupplierJob={selectedSupplierJob}
        handleClose={() => {
          onReload();
          setEditJob(false);
        }}
      />
      <AppCreateReleaseModal
        open={openCreateReleaseModal}
        handleClose={() => {
          setOpenCreateReleaseModal(false);
          setSignature('');
        }}
        selectedJob={selectedSupplierJob}
        onSignSignature={() => {
          setIsCreateSignatureModalOpen(true);
        }}
        signature={signature}
      />
      <CreateSignatureModal
        open={isCreateSignatureModalOpen}
        handleClose={() => {
          setIsCreateSignatureModalOpen(false);
        }}
        addSignature={handleAddSignature}
        onDownloadRealease={() => {}}
        defaultIsManualSign={false}
      />
      <AppEditFirstDeliveryDateModal
        open={editFirstDeliveryDate}
        handleClose={() => {
          setSoftFirstDeliveryDate(null);
          setEditFirstDeliveryDate(false);
          onReload();
        }}
        dateType={dateModalType}
        selectedSupplierJob={selectedSupplierJob}
        softDeliveryDate={softFirstDeliveryDate}
      />
      {supplierJobs.length === 0 && (
        <div className="w-full h-full flex items-center justify-center">
          <div className="flex flex-col items-center">
            <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
            <p>No Supplier Jobs</p>
          </div>
        </div>
      )}
      <ReactTooltip
        id={'soft-delivery-date-tooltip'}
        place="right"
        variant="dark"
        opacity={'100%'}
        style={{
          background: '#222',
          width: '300px',
          zIndex: 99999,
        }}>
        <span>Please confirm delivery date. This is a best guess based on the invoice date.</span>
      </ReactTooltip>
      {supplierJobs.length > 0 && (
        <div>
          <div
            className="flex border-b justify-between sticky top-0 PRIMARY_50-BG z-10 px-5"
            style={{
              width: width <= 1810 ? 1810 : width,
            }}>
            {columns.map((column) => {
              return (
                <div
                  key={column.id}
                  style={{
                    minWidth: 100,
                    flex: column.width / (totalWidth <= 1810 ? 1810 : width),
                    width: `${(column.width / totalWidth) * (width <= 1810 ? 1810 : width)}px`, // Adjusted width calculation
                  }}
                  className="py-3">
                  <p className="text-xs TEXT_SECONDARY-CLR">{column.name}</p>
                </div>
              );
            })}
          </div>
          <AppVirtualizedList
            itemsLength={supplierJobs.length}
            itemHeight={70}
            containerHeight={100}
            renderRow={renderRow}
            topOffset={285}
            className=""
            containerStyle={{
              paddingBottom: '150px',
              width: width <= 1810 ? '1810px' : `${width}`,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SupplierJobsTable;
