import { logEvent } from 'firebase/analytics';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Download, FilePlus, Filter, Search } from 'react-feather';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { myAnalytics, myRealtimeDB } from '../../../../firebase/config';
import {
  resetInvoiceIdsForDownload,
  updateBuyerSupplierInvoicesDataWeeksLimit,
} from '../../../../redux/globalSlices/buyersSlice';
import {
  updateSelectedSupplierForBuyer,
  resetSelectInvoice,
} from '../../../../redux/globalSlices/invoiceSlice';
import {
  downloadMultiInvoicePdfZip,
  getGroupedBuyersSuppliers,
  getInvoicePdf,
} from '../../../../redux/services/buyersService';
import {
  getMainBuyerPaymentMethods,
  getWeekPaymentTransactionsLimitationsForMainBuyer,
} from '../../../../redux/services/paymentsService';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { COLORS } from '../../../../utils/colors';
import { weeksOptions } from '../../../../utils/constants';
import {
  InvoiceStatus,
  QuickbooksInvoice,
  Supplier,
  BuyersGroup,
  roundTo,
  QuickbooksJob,
  removeDuplicatesByProperty,
  SupplierForBuyer,
  QuickbooksCreditMemo,
  QuickbooksInvoiceSchema,
  QuickbooksCreditMemoSchema,
} from '@dill/dill-shared';
import { AppButton } from '../../../general/AppButton/AppButton';
import { AppDropDown } from '../../../general/AppDropDown/AppDropDown';
import { AppInputField } from '../../../general/AppInputField/AppInputField';
import AppSelectedPayInvoicesModal from '../../../general/AppSelectedPayInvoicesModal/AppSelectedPayInvoicesModal';
import { InvoicesTable } from '../../invoices/InvoicesTable/InvoicesTable';
import { getLienTemplates } from '../../../../redux/services/leinTemplatesService';
import AppLastSyncDataView from '../../../general/AppLastSyncDataView/AppLastSyncDataView';
import { onValue, ref } from 'firebase/database';
import type { Unsubscribe } from 'firebase/database';
import { AppDropDown2 } from '../../../general/AppDropDown2/AppDropDown2';
import { AppCheckBox } from '../../../general/AppCheckBox/AppCheckBox';
import { twMerge } from 'tailwind-merge';
import { FunnelSimple } from 'phosphor-react';
import { updateLastSyncTimeBuyer } from '../../../../redux/globalSlices/genericSlice';
import { useParamMainBuyerId } from '../../../../utils/hooks/useParamMainBuyerId';
import { getUserByBuyers } from '../../../../redux/services/authService';
import { mainBuyerDownloadSupplierInvoiceStatements } from '../../../../redux/services/invoicesService';
import { openDillToast, truncateText } from '../../../../utils/helpers';
import { AppDropdownButton } from '../../../general/AppDropdownButton/AppDropdownButton';

export interface InvoiceTabs {
  id: InvoiceStatus;
  name: string;
  totalAmount: number;
  pastDueCount?: number;
  invoices: (QuickbooksInvoice | QuickbooksCreditMemo)[];
  credits: QuickbooksCreditMemo[];
}
export const ViewSupplierInvoices = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const {
    selectedBuyerSuppliers,
    invoiceIdsForDownload,
    loadingList: buyersLoadingList,
    buyerSupplierInvoicesDataWeeksLimit,
  } = useAppSelector((state) => state.buyers);
  const { user } = useAppSelector((state) => state.auth);
  const { selectedMainBuyer } = useAppSelector((state) => state.buyers);
  const { selectedInvoices } = useAppSelector((state) => state.invoice);
  const [selectedTabId, setSelectedTabId] = useState<InvoiceStatus>('OPEN');
  const [selectedJobIds, setSelectedJobIds] = useState<string[]>([]);
  const [selectedInvoiceFilterId, setSelectedInvoiceFilterId] = useState<
    'SHOW_OPEN' | 'SHOW_PAST_DUE' | null
  >(null);

  const [searchText, setSearchText] = useState('');
  const [payInvoicesModalOpen, setPayInvoicesModalOpen] = useState(false);
  const [invoicesToBePaid, setInvoicesToBePaid] = useState([] as QuickbooksInvoice[]);
  const [invoiceForPreview, setInvoiceForPreview] = useState<QuickbooksInvoice | null | undefined>(
    null
  );
  const [selectedMainBuyerId] = useParamMainBuyerId();

  const invoicesFilterOptions: { name: string; id: 'SHOW_OPEN' | 'SHOW_PAST_DUE' }[] = useMemo(
    () => [
      { name: 'Show all open invoices', id: 'SHOW_OPEN' },
      { name: 'Show past due invoices', id: 'SHOW_PAST_DUE' },
    ],
    []
  );

  useEffect(() => {
    // dispatch(resetSelectInvoice());
  }, [selectedTabId]);

  useEffect(() => {
    if (selectedMainBuyerId && user?.userMainBuyers) {
      const mainBuyer = user?.userMainBuyers.find((mb) => mb.id === selectedMainBuyerId);
      if (!mainBuyer) {
        navigate('/companies');
      }
    }
  }, [user?.userMainBuyers]);

  useEffect(() => {
    if (selectedMainBuyerId) {
      dispatch(getUserByBuyers({ buyerId: selectedMainBuyerId }));
    }
    dispatch(resetInvoiceIdsForDownload());
    dispatch(resetSelectInvoice());
  }, [selectedMainBuyerId]);
  useEffect(() => {
    if (selectedMainBuyerId && !buyersLoadingList.includes('getGroupedBuyersSuppliers')) {
      dispatch(
        getGroupedBuyersSuppliers({
          mainBuyerId: selectedMainBuyerId,
          weeksLimit: buyerSupplierInvoicesDataWeeksLimit,
        })
      );
    }

    return () => {};
  }, [buyerSupplierInvoicesDataWeeksLimit, selectedMainBuyerId]);

  useEffect(() => {
    let newSupplier: SupplierForBuyer | null = null;

    if (params.supplierDomainId) {
      const supplierDomainId = params.supplierDomainId;

      const supplier: SupplierForBuyer | undefined = selectedBuyerSuppliers.find(
        (item: Supplier) => item.domainId === supplierDomainId
      );

      if (supplier) {
        newSupplier = supplier;
      } else {
        newSupplier = null;
      }
    }
    dispatch(updateSelectedSupplierForBuyer(newSupplier));
  }, [params.supplierDomainId, selectedMainBuyerId, selectedBuyerSuppliers]);

  const selectedSupplier = useMemo(() => {
    let newSupplier: SupplierForBuyer | null = null;

    if (params.supplierDomainId) {
      const supplierDomainId = params.supplierDomainId;

      const supplier: SupplierForBuyer | undefined = selectedBuyerSuppliers.find(
        (item: Supplier) => item.domainId === supplierDomainId
      );

      if (supplier) {
        newSupplier = supplier;
      } else {
        newSupplier = null;
      }
    }
    return newSupplier;
  }, [location.pathname, selectedBuyerSuppliers]);

  const selectedBuyer = useMemo(() => {
    if (selectedSupplier) {
      return selectedSupplier.buyer;
    }
    return null;
  }, [selectedSupplier]);

  const invoicesTabs = useMemo(() => {
    const allOpenInvoices = [
      ...(selectedSupplier?.invoices?.pastDueInvoices.invoices || []),
      ...(selectedSupplier?.invoices?.currentInvoices.invoices || []),
      ...(selectedSupplier?.invoices?.creditMemosDetails.creditMemos || []),
    ];
    const pp: InvoiceTabs[] = [
      {
        id: 'OPEN',
        name: 'Open Items',
        totalAmount:
          (selectedSupplier?.invoices?.pastDueInvoices?.totalAmount || 0) +
          (selectedSupplier?.invoices?.currentInvoices?.totalAmount || 0),
        invoices: [...allOpenInvoices],
        credits: selectedSupplier?.invoices?.creditMemosDetails.creditMemos || [],
      },
      {
        id: 'PENDING_PAY',
        name: 'Pending',
        totalAmount: selectedSupplier?.invoices?.pendingPayInvoices?.totalAmount || 0,
        invoices: selectedSupplier?.invoices?.pendingPayInvoices.invoices || [],
        credits: [],
      },
      {
        id: 'PAID',
        name: 'Paid',
        totalAmount: selectedSupplier?.invoices?.paidInvoices?.totalAmount || 0,
        invoices: selectedSupplier?.invoices?.paidInvoices.invoices || [],
        credits: [],
      },
    ];
    if (
      allOpenInvoices.length === 0 &&
      selectedSupplier?.invoices &&
      selectedSupplier?.invoices?.pendingPayInvoices.invoices.length > 0
    ) {
      setSelectedTabId('PENDING_PAY');
    }
    return pp;
  }, [selectedSupplier, location.pathname]);
  const tableInvoices: (QuickbooksInvoice | QuickbooksCreditMemo)[] = useMemo(() => {
    if (selectedSupplier && selectedTabId) {
      const tab = invoicesTabs.find((tabDetails) => tabDetails.id === selectedTabId);

      logEvent(myAnalytics, 'buyer_view_tab', {
        userId: user ? user.id : '',
        supplierId: selectedSupplier.id,
        status: selectedTabId.toLocaleLowerCase().replaceAll('_', ' '),
        time: new Date(),
        route: location.pathname,
      });
      if (tab) {
        return [...tab.invoices];
      } else {
        return [];
      }
    }

    return [];
  }, [invoicesTabs, selectedTabId]);
  const selectedSupplierJobs = useMemo(() => {
    if (tableInvoices) {
      const jobs = invoicesTabs
        .reduce((prev: (QuickbooksInvoice | QuickbooksCreditMemo)[], curr) => {
          return [...prev, ...curr.invoices];
        }, [])
        .reduce((prev: QuickbooksJob[], curr) => {
          if (curr.jobId && curr.quickBooksJob) {
            return [...prev, curr.quickBooksJob];
          } else {
            return prev;
          }
        }, []);

      return removeDuplicatesByProperty({ inputArray: jobs, property: 'id' });
    }

    return tableInvoices;
  }, [invoicesTabs, selectedTabId]);

  const searchedTableInvoices = useMemo(() => {
    let finalSearchedInvoices = [];
    if (tableInvoices) {
      const searched = tableInvoices.filter((documentDetails) => {
        const invoiceCheck = QuickbooksInvoiceSchema.safeParse(documentDetails);
        const creditMemoCheck = QuickbooksCreditMemoSchema.safeParse(documentDetails);
        let invoice: QuickbooksInvoice | null = null;
        let creditMemo: QuickbooksCreditMemo | null = null;
        if (invoiceCheck.success) {
          invoice = invoiceCheck.data;
        }
        if (creditMemoCheck.success) {
          creditMemo = creditMemoCheck.data;
        }
        let filters: {
          isMatchTextFilter?: boolean;
          isMatchJobFilter?: boolean;
          isMatchInvoiceFilter?: boolean;
        } = {};
        if (searchText) {
          if (
            (invoice &&
              ((invoice?.quickBooksJob?.jobName &&
                invoice?.quickBooksJob?.jobName
                  .toString()
                  .toLowerCase()
                  .includes(searchText.toLowerCase())) ||
                (invoice?.quickBooksJob?.address &&
                  invoice?.quickBooksJob?.address
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (invoice?.quickBooksJob?.jobNumber &&
                  invoice?.quickBooksJob?.jobNumber
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (invoice?.poNumber &&
                  invoice?.poNumber.toString().toLowerCase().includes(searchText.toLowerCase())) ||
                (invoice?.amount &&
                  invoice?.amount.toString().toLowerCase().includes(searchText.toLowerCase())) ||
                (invoice?.terms &&
                  invoice?.terms.toString().toLowerCase().includes(searchText.toLowerCase())) ||
                (invoice?.dueDate &&
                  moment(invoice?.dueDate)
                    .format('MM/DD/YYYY')
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (invoice?.createdAt &&
                  moment(invoice?.createdAt)
                    .format('MM/DD/YYYY')
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (invoice?.docNumber &&
                  invoice?.docNumber.toString().toLowerCase().includes(searchText.toLowerCase())) ||
                (invoice?.balance &&
                  invoice?.balance.toString().toLowerCase().includes(searchText.toLowerCase())) ||
                (invoice?.recordType &&
                  invoice?.recordType
                    .toString()
                    .toLowerCase()
                    .replace('_', ' ')
                    .includes(searchText.toLowerCase())))) ||
            (creditMemo &&
              ((creditMemo?.quickBooksJob?.jobName &&
                creditMemo?.quickBooksJob?.jobName
                  .toString()
                  .toLowerCase()
                  .includes(searchText.toLowerCase())) ||
                (creditMemo?.quickBooksJob?.address &&
                  creditMemo?.quickBooksJob?.address
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.quickBooksJob?.jobNumber &&
                  creditMemo?.quickBooksJob?.jobNumber
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.docNumber &&
                  creditMemo?.docNumber
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.totalAmount &&
                  creditMemo?.totalAmount
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.jobName &&
                  creditMemo?.jobName
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.createdAt &&
                  moment(creditMemo?.createdAt)
                    .format('MM/DD/YYYY')
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.remainingCredit &&
                  creditMemo?.remainingCredit
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())) ||
                (creditMemo?.recordType &&
                  creditMemo?.recordType
                    .toString()
                    .toLowerCase()
                    .replace('_', ' ')
                    .includes(searchText.toLowerCase()))))
          ) {
            filters = { ...filters, isMatchTextFilter: true };
          } else {
            filters = { ...filters, isMatchTextFilter: false };
          }
        }
        if (selectedJobIds.length > 0) {
          if (invoice && invoice?.quickBooksJob) {
            filters = {
              ...filters,
              isMatchJobFilter:
                invoice?.quickBooksJob && selectedJobIds?.includes(invoice.quickBooksJob.id),
            };
          } else if (creditMemo && creditMemo?.quickBooksJob) {
            filters = {
              ...filters,
              isMatchJobFilter:
                creditMemo?.quickBooksJob && selectedJobIds?.includes(creditMemo.quickBooksJob.id),
            };
          } else {
            filters = {
              ...filters,
              isMatchJobFilter: false,
            };
          }
        }
        if (selectedInvoiceFilterId) {
          if (
            invoice &&
            selectedInvoiceFilterId === 'SHOW_OPEN' &&
            (invoice.status === 'PAST_DUE' || invoice.status === 'CURRENT_DUE')
          ) {
            filters = { ...filters, isMatchInvoiceFilter: true };
          } else if (
            invoice &&
            selectedInvoiceFilterId === 'SHOW_PAST_DUE' &&
            invoice.status === 'PAST_DUE'
          ) {
            filters = { ...filters, isMatchInvoiceFilter: true };
          } else {
            filters = { ...filters, isMatchInvoiceFilter: false };
          }
        }

        return Object.values(filters).every((item) => item);
      });
      finalSearchedInvoices = searched;
    } else {
      finalSearchedInvoices = tableInvoices;
    }

    return finalSearchedInvoices;
  }, [tableInvoices, searchText, selectedJobIds, selectedInvoiceFilterId]);

  const handleDownloadAll = async ({ combined }: { combined: boolean }) => {
    if (Object.values(invoiceIdsForDownload).flat().length > 0 && selectedSupplier?.domainId) {
      if (Object.values(invoiceIdsForDownload).flat().length === 1) {
        const res = await dispatch(
          getInvoicePdf({
            supplierDomainId: selectedSupplier?.domainId,
            invoiceId: Object.values(invoiceIdsForDownload).flat()[0],
          })
        );
        if (res && res.type === 'buyers/getInvoicePdf/fulfilled') {
          openDillToast({ message: 'invoice pdf successfully downloaded', type: 'SUCCESS' });
        } else {
          openDillToast({ message: 'Failed to download invoice pdf', type: 'ERROR' });
        }
      } else {
        const res = await dispatch(
          downloadMultiInvoicePdfZip({
            supplierDomainId: selectedSupplier?.domainId || '',
            invoiceIds: Object.values(invoiceIdsForDownload).flat(),
            combined,
          })
        );
        if (res && res.type === 'buyers/downloadMultiInvoicePdfZip/fulfilled') {
          openDillToast({ message: 'invoice pdfs successfully downloaded', type: 'SUCCESS' });
        } else {
          openDillToast({ message: 'Failed to download invoice pdfs', type: 'ERROR' });
        }
      }
    }
  };

  const handlePayBulkInvoice = async () => {
    await setInvoicesToBePaid(Object.values(selectedInvoices).flat());
    setPayInvoicesModalOpen(true);
  };

  const handlePaySingleInvoice = async (invoice: QuickbooksInvoice) => {
    await setInvoicesToBePaid([invoice]);
    setPayInvoicesModalOpen(true);
  };

  const getData = (supplierId: string) => {
    const lastSyncTimeRef = ref(myRealtimeDB, `lastSyncTime/${supplierId}`);
    const subScription = onValue(lastSyncTimeRef, (snapshot) => {
      const data = snapshot.val();
      dispatch(updateLastSyncTimeBuyer(data));
    });
    return subScription;
  };

  useEffect(() => {
    let subscription: Unsubscribe | null = null;
    if (selectedSupplier) {
      subscription = getData(selectedSupplier.id);
    }
    return () => {
      if (subscription) {
        subscription();
      }
    };
  }, [selectedSupplier]);

  useEffect(() => {
    if (
      selectedSupplier &&
      location.pathname.startsWith(
        `/companies/${selectedMainBuyerId}/invoices/${selectedSupplier.domainId}`
      )
    ) {
      const searchParams = new URLSearchParams(location.search);
      const invoiceId = searchParams.get('invoiceId');
      if (invoiceId) {
        let tabIdWithInvoice: InvoiceStatus | null = null;
        let selectedInvoice: QuickbooksInvoice | null = null;
        invoicesTabs.forEach((tabData) => {
          const invoice = tabData.invoices.find(
            (invoiceDetails) => invoiceDetails.id === invoiceId
          );
          const invoiceCheck = QuickbooksInvoiceSchema.safeParse(invoice);
          if (invoiceCheck.success && invoice) {
            tabIdWithInvoice = tabData.id;
            selectedInvoice = invoiceCheck.data;
          }
        });

        if (selectedInvoice && tabIdWithInvoice) {
          setInvoiceForPreview(selectedInvoice);
          setSelectedTabId(tabIdWithInvoice);
        }
      }
    }
  }, [location.pathname, invoicesTabs, selectedSupplier, selectedTabId]);

  const accumulateAmount = useMemo(() => {
    const flatInvoices = Object.values(selectedInvoices).flat();
    if (flatInvoices.length > 0) {
      const totalBalance = flatInvoices.reduce((acc, invoice) => acc + (invoice.balance ?? 0), 0);
      return '$' + totalBalance.toFixed(2);
    }
    return '$0.00';
  }, [selectedInvoices]);

  return (
    <div className="w-full h-full">
      {payInvoicesModalOpen && (
        <AppSelectedPayInvoicesModal
          open={payInvoicesModalOpen}
          title="Bulk Pay"
          handleClose={() => {
            setInvoicesToBePaid([]);
            setPayInvoicesModalOpen(false);
          }}
          selectedInvoices={invoicesToBePaid}
          paymentTo={selectedSupplier?.name?.toLocaleLowerCase()}
          paymentFrom={selectedMainBuyer?.name.toLocaleLowerCase()}
        />
      )}
      <div className="flex h-full flex-col mx-10 mt-3  p-3  pb-6 rounded-lg WHITE-BG  flex-nowrap relative">
        <div className="mt-0 py-2 flex justify-between">
          <div className="flex z-20 items-center">
            <div className="w-[250px]">
              <AppInputField
                inputFieldStyles={{ width: '250px' }}
                id="search"
                placeholder={'Search '}
                icon={<Search size={15} />}
                onTextChange={(text) => {
                  setSearchText(text);
                }}
              />
            </div>

            <div className="flex items-center h-full">
              {selectedSupplierJobs.length > 0 && (
                <div className="relative">
                  <AppDropDown2
                    inputFieldStyles={{ width: '250px', marginLeft: '8px' }}
                    placeholder=""
                    customViewElement={
                      <div className="flex my-1 px-2 items-center">
                        <FunnelSimple size={18} />
                        <p className="text-sm ml-2 text-gray-600">Jobs Filter</p>
                      </div>
                    }
                    isSearchable={true}
                    isCreatable={false}
                    closeMenu={false}
                    items={[
                      ...[
                        ...(selectedSupplierJobs.length > 0
                          ? [{ jobName: 'All Jobs', id: 'ALL' }]
                          : []),
                        ...selectedSupplierJobs,
                      ].map((job) => {
                        const selectedJob = selectedSupplierJobs.find((j) => j.id === job.id);
                        const jobAddress = `${selectedJob?.address ?? ''} ${
                          selectedJob?.city ?? ''
                        } ${selectedJob?.state ? `${selectedJob?.state},` : ''} ${
                          selectedJob?.zipCode ?? ''
                        }`.trim();
                        const jobName = `${
                          selectedJob?.jobNumber ? `${selectedJob?.jobNumber} - ` : ''
                        }${job.jobName}`;
                        return {
                          label: jobName,
                          value: job.id,
                          labelInElement: (
                            <div className="flex my-1 mx-2 items-center ">
                              <AppCheckBox
                                className="mr-2 h-5 w-5 "
                                checkSize={20}
                                isChecked={selectedJobIds?.includes(job.id)}
                              />
                              <div className="flex-col">
                                <p
                                  className={twMerge([
                                    'font-bold text-xs word-wrap',
                                    selectedJobIds?.includes(job.id)
                                      ? 'text-blue-700'
                                      : 'text-black',
                                  ])}>
                                  {jobName}
                                </p>
                                {jobAddress !== '' && (
                                  <p
                                    className={twMerge([
                                      'text-2xs word-wrap',
                                      selectedJobIds?.includes(job.id)
                                        ? 'text-blue-700'
                                        : 'text-black',
                                    ])}>
                                    {truncateText(jobAddress, 35)}
                                  </p>
                                )}
                              </div>
                            </div>
                          ),
                        };
                      }),
                    ]}
                    isMulti
                    onMultiSelectChange={(data, oldMultiValue) => {
                      dispatch(resetInvoiceIdsForDownload());
                      dispatch(resetSelectInvoice());
                      if (data) {
                        const valueList = data.map((item) => item.value);
                        const valueListWithoutAll = valueList.filter((item) => item !== 'ALL');
                        if (
                          !valueList.includes('ALL') &&
                          oldMultiValue &&
                          oldMultiValue.includes('ALL') &&
                          valueListWithoutAll.length === selectedSupplierJobs.length
                        ) {
                          setSelectedJobIds([]);
                        } else if (
                          valueList.includes('ALL') &&
                          valueListWithoutAll.length < selectedSupplierJobs.length &&
                          oldMultiValue &&
                          oldMultiValue.includes('ALL')
                        ) {
                          setSelectedJobIds(valueListWithoutAll);
                        } else if (
                          (valueList.includes('ALL') &&
                            valueListWithoutAll.length === 0 &&
                            oldMultiValue &&
                            !oldMultiValue.includes('ALL')) ||
                          valueList.includes('ALL') ||
                          (valueListWithoutAll.length === selectedSupplierJobs.length &&
                            !valueList.includes('ALL'))
                        ) {
                          setSelectedJobIds([
                            'ALL',
                            ...selectedSupplierJobs.map((item) => item.id),
                          ]);
                        } else if (valueList.length > 0) {
                          setSelectedJobIds(valueList);
                        } else {
                          setSelectedJobIds([]);
                        }
                      }
                    }}
                    value={null}
                    multiValue={selectedJobIds}
                  />
                  {selectedJobIds.filter((item) => item !== 'ALL')?.length > 0 && (
                    <div className="flex absolute bottom-6 right-[-12px] PRIMARY_500-BG WHITE-CLR rounded-full text-xs font-bold h-8 w-8 items-center justify-center">
                      {selectedJobIds.filter((item) => item !== 'ALL')?.length}
                    </div>
                  )}
                </div>
              )}
              {selectedTabId === 'OPEN' && (
                <div className="flex flex-row mx-1 h-full">
                  {invoicesFilterOptions.map((option, i) => {
                    return (
                      <div
                        key={`${i}-o`}
                        className="flex items-center my-0.5 mx-1 cursor-pointer"
                        onClick={() => {
                          dispatch(resetInvoiceIdsForDownload());
                          dispatch(resetSelectInvoice());
                          if (selectedInvoiceFilterId !== option.id) {
                            setSelectedInvoiceFilterId(option.id);
                          } else {
                            setSelectedInvoiceFilterId(null);
                          }
                        }}>
                        <AppCheckBox
                          className="w-4 h-4 p-[1px]"
                          isChecked={selectedInvoiceFilterId === option.id}
                        />
                        <p className="text-2xs ml-1">{option.name}</p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {selectedTabId === 'PAID' && (
              <div className="flex flex-col ml-3 z-20 ">
                <p className="text-2xs font-bold mb-0.5">Invoice Date</p>
                <AppDropDown
                  placeholder="Invoice Date"
                  inputFieldStyles={{ width: '200px', height: '40px' }}
                  isSearchable={false}
                  isCreatable={false}
                  items={[...weeksOptions]}
                  value={buyerSupplierInvoicesDataWeeksLimit.toString()}
                  onSelectChange={(data) => {
                    if (data?.value) {
                      dispatch(updateBuyerSupplierInvoicesDataWeeksLimit(parseInt(data?.value)));
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex pt-2">
            {Object.values(invoiceIdsForDownload).flat().length > 0 &&
              (selectedSupplier?.integrationType === 'QBO' ||
                selectedSupplier?.integrationType === 'RUTTER_QBD' ||
                selectedSupplier?.integrationType === 'RUTTER_NETSUITE' ||
                selectedSupplier?.integrationType === 'MANUAL_UPLOAD' ||
                selectedSupplier?.integrationType === 'INFOR_DIST_SX') && (
                <div className="relative mr-1">
                  {Object.values(invoiceIdsForDownload).flat().length === 1 ? (
                    <AppButton
                      text="Bulk Download"
                      type="SECONDARY"
                      onClick={() => handleDownloadAll({ combined: true })}
                    />
                  ) : (
                    <AppDropdownButton
                      options={[
                        { label: 'Separate files', value: 'separate' },
                        { label: 'One file', value: 'onefile' },
                      ]}
                      onSelect={(option) => {
                        if (option.value === 'onefile') {
                          handleDownloadAll({ combined: true });
                        } else {
                          handleDownloadAll({ combined: false });
                        }
                      }}
                      buttonTitle="Bulk Download"
                      buttonType="SECONDARY"
                    />
                  )}
                  <div className="flex absolute -top-2 right-[-12px] PRIMARY_500-BG WHITE-CLR rounded-full text-2xs font-bold h-6 w-6 items-center border border-white justify-center">
                    {Object.values(invoiceIdsForDownload).flat()?.length}
                  </div>
                </div>
              )}
            {Object.values(selectedInvoices).flat() &&
              Object.values(selectedInvoices).flat().length > 0 && (
                <div className="relative grid justify-items-end">
                  <AppButton
                    text="Bulk Pay"
                    buttonStyles={{ marginLeft: '10px' }}
                    onClick={handlePayBulkInvoice}
                    isDisabled={!selectedSupplier?.isFullyVerified}
                  />

                  <div className="flex absolute -top-2 right-[-12px] PRIMARY_500-BG WHITE-CLR rounded-full text-2xs font-bold h-6 w-6 items-center border border-white justify-center">
                    {Object.values(selectedInvoices).flat()?.length}
                  </div>
                  <div className="TEXT_SECONDARY-CLR mt-1 mr-2 text-sm">{accumulateAmount}</div>
                </div>
              )}
          </div>
        </div>

        <div className="absolute right-2 top-1 flex items-center">
          <AppLastSyncDataView dataKey="invoices" from="buyer" />
        </div>
        <div className="flex flex-nowrap">
          {invoicesTabs.map((tab, index) => {
            return (
              <div
                key={index + 'oo'}
                className={
                  'flex flex-col border-b-1 border-b-slate-500 w-full  cursor-pointer ' +
                  (selectedTabId === tab.id ? '' : 'opacity-60')
                }
                onClick={() => {
                  const searchParams = new URLSearchParams(location.search);
                  const invoiceId = searchParams.get('invoiceId');
                  if (invoiceId) {
                    navigate(location.pathname);
                  }
                  setSelectedTabId(tab.id);
                }}>
                <p className="font-bold text-lg mb-2">{tab.name}</p>
                <div className="flex mb-2 justify-between">
                  <div className="flex items-center ">
                    <div
                      style={{ height: 10, width: 10 }}
                      className={
                        'rounded-full p-1 ' +
                        (tab.id === 'PAST_DUE'
                          ? 'WARNING_500-BG '
                          : tab.id === 'CURRENT_DUE'
                          ? ' INFO_500-BG'
                          : tab.id === 'PAID'
                          ? ' SUCCESS_500-BG'
                          : tab.id === 'PENDING_PAY'
                          ? ' PRIMARY_500-BG'
                          : ' WARNING_500-BG')
                      }></div>
                    <p className="text-sm ml-1 font-semibold">{`$${roundTo(
                      tab.totalAmount,
                      2
                    )}`}</p>
                    <p className="text-sm TEXT_SECONDARY-CLR mx-1">|</p>
                    <p className="text-sm TEXT_SECONDARY-CLR ">{tab.invoices.length} items</p>
                  </div>
                </div>

                {selectedTabId === tab.id && (
                  <div style={{ height: '2px' }} className="h-1 w-full PRIMARY_500-BG"></div>
                )}
                {selectedTabId !== tab.id && (
                  <div style={{ height: '2px' }} className="h-1 w-full GREY_300-BG"></div>
                )}
              </div>
            );
          })}
        </div>

        <div
          className="py-3  "
          style={{
            minHeight: `calc(100% - ${
              Object.values(selectedInvoices).flat()?.length > 0 ? '240px' : '216px'
            })`,
          }}>
          {tableInvoices && selectedTabId && (
            <InvoicesTable
              invoices={searchedTableInvoices}
              selectedTabId={selectedTabId}
              onPaySingleInvoice={handlePaySingleInvoice}
              selectedInvoiceForPreview={invoiceForPreview}
            />
          )}
        </div>
      </div>
    </div>
  );
};
