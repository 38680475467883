import { createSlice } from '@reduxjs/toolkit';
import { getSage100InvoicesExtraReducer } from '../reducers/sage100Reducers';

export interface Sage100InitialState {
  loadingList: string[];
  sage100Invoices: any[];
}

const initialState: Sage100InitialState = {
  loadingList: [],
  sage100Invoices: [],
};

export const sage100Slice = createSlice({
  name: 'distributionOne',
  initialState,
  reducers: {
    resetSage100RootState: () => initialState,
  },
  extraReducers(builder) {
    getSage100InvoicesExtraReducer(builder);
  },
});

export const { resetSage100RootState } = sage100Slice.actions;
