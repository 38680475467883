import { useEffect, useMemo } from 'react';
import AppRouter from './AppRouter';
import { useAppDispatch, useAppSelector } from './redux/store';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getStripePublishableKey } from './redux/services/authService';
import { AppProgressSpinner } from './components/general/AppProgressSpinner/AppProgressSpinner';
import { useAuth } from './utils/hooks/useAuth';
import { onValue, ref } from 'firebase/database';
import { myRealtimeDB } from './firebase/config';
import { toast } from 'react-toastify';
import type { Unsubscribe } from 'firebase/database';
import {
  closeMessageModal,
  updateIsSupplierSyncing,
  updateLastSyncTimeSupplier,
  updateSupplierQueueJobsRunning,
} from './redux/globalSlices/genericSlice';
import { AppMessageModal } from './components/general/AppMessageModal/AppMessageModal';

function AppSetup() {
  const dispatch = useAppDispatch();
  const { loadingList: buyerLoadingList } = useAppSelector((state) => state.buyers);
  const { loadingList: invoiceLoadingList } = useAppSelector((state) => state.invoice);
  const {
    loadingList: authLoadingList,
    user,
    stripePublishableKey,
    firebaseUserId,
  } = useAppSelector((state) => state.auth);
  const { loadingList: supplierLoadingList } = useAppSelector((state) => state.supplier);
  const { loadingList: paymentLoadingList } = useAppSelector((state) => state.payments);
  const { loadingList: salesReceiptsLoadingList } = useAppSelector((state) => state.salesReceipts);
  const { loadingList: lienTemplatesLoadingList } = useAppSelector((state) => state.lienTemplates);
  const { isMessageModalOpen, messageModalDetails } = useAppSelector((state) => state.generic);

  const { loadingList: subSuppliersLoadingList, loadingErrorList: subSupplierError } =
    useAppSelector((state) => state.subSuppliers);
  const { loadingList: liensLoadingList, loadingErrorList: releaseError } = useAppSelector(
    (state) => state.liens
  );
  const { loadingList: jobsLoadingList, loadingErrorList: jobsError } = useAppSelector(
    (state) => state.jobs
  );
  const { loadingList: preliminaryNoticesLoadingList } = useAppSelector(
    (state) => state.preliminaryNotices
  );
  const { loadingList: noticeTemplatesLoadingList } = useAppSelector(
    (state) => state.noticeTemplates
  );
  const { loadingList: supplierJobsLoadingList } = useAppSelector((state) => state.supplierJobs);
  const { loadingList: supplierBuyersLoadingList } = useAppSelector(
    (state) => state.supplierBuyers
  );
  const { loadingList: manualUploadLoadingList } = useAppSelector((state) => state.manualUpload);
  useAuth();
  const getLastSyncTimeData = (supplierId: string) => {
    const lastSyncTimeRef = ref(myRealtimeDB, `lastSyncTime/${supplierId}`);
    const subScription = onValue(lastSyncTimeRef, (snapshot) => {
      const data = snapshot.val();
      dispatch(updateLastSyncTimeSupplier(data));
    });
    return subScription;
  };

  const getSupplierQueueJobsRunning = (supplierId: string) => {
    const lastSyncTimeRef = ref(myRealtimeDB, `suppliersRunningJobs/${supplierId}`);
    const subScription = onValue(lastSyncTimeRef, (snapshot) => {
      const data = snapshot.val();
      if (data && Object.keys(data).length > 0) {
        dispatch(updateSupplierQueueJobsRunning(Object.keys(data)));
      } else {
        dispatch(updateSupplierQueueJobsRunning([]));
      }
    });
    return subScription;
  };

  const geSyncingSupplierData = (supplierId: string) => {
    const lastSyncTimeRef = ref(myRealtimeDB, `syncingSupplier`);
    const subScription = onValue(lastSyncTimeRef, (snapshot) => {
      const data = snapshot.val();
      if (data && data.supplierId === supplierId) {
        dispatch(updateIsSupplierSyncing(true));
      } else {
        dispatch(updateIsSupplierSyncing(false));
      }
    });
    return subScription;
  };
  useEffect(() => {
    if (!stripePublishableKey) {
      dispatch(getStripePublishableKey());
    }

    return () => {};
  }, []);

  const stripePromise = useMemo(() => {
    if (stripePublishableKey) {
      const key = loadStripe(stripePublishableKey);
      return key;
    }

    return null;
  }, [stripePublishableKey]);

  useEffect(() => {
    let lastSyncTimeUnSubscribe: Unsubscribe | null = null;
    let syncingSupplierUnSubscribe: Unsubscribe | null = null;
    let getSupplierQueueJobsRunningUnSubscribe: Unsubscribe | null = null;
    if (user?.userSupplier?.id) {
      lastSyncTimeUnSubscribe = getLastSyncTimeData(user?.userSupplier?.id);
      syncingSupplierUnSubscribe = geSyncingSupplierData(user?.userSupplier?.id);
      getSupplierQueueJobsRunningUnSubscribe = getSupplierQueueJobsRunning(user?.userSupplier?.id);
    }
    return () => {
      if (lastSyncTimeUnSubscribe) {
        lastSyncTimeUnSubscribe();
      }
      if (syncingSupplierUnSubscribe) {
        syncingSupplierUnSubscribe();
      }
      if (getSupplierQueueJobsRunningUnSubscribe) {
        getSupplierQueueJobsRunningUnSubscribe();
      }
    };
  }, [user?.userSupplier?.id]);

  const showErrorMessage = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      style: {
        backgroundColor: '#FEF0EF',
        color: '#F04438',
      },
    });
  };

  useEffect(() => {
    if (jobsError.length > 0) {
      showErrorMessage('Error loading jobs!');
    }
  }, [jobsError]);
  useEffect(() => {
    if (releaseError.length > 0) {
      const releaseErrorList = releaseError.find((item) => item?.getRelease);
      showErrorMessage(releaseErrorList?.getRelease?.[0] ?? 'Error loading releases!');
    }
  }, [releaseError]);
  useEffect(() => {
    if (subSupplierError.length > 0) {
      showErrorMessage('Error loading suppliers!');
    }
  }, [subSupplierError]);

  // console.log(
  //   JSON.stringify(
  //     {
  //       invoiceLoadingList,
  //       buyerLoadingList,
  //       salesReceiptsLoadingList,
  //       liensLoadingList,
  //       supplierLoadingList,
  //       lienTemplatesLoadingList,
  //       subSuppliersLoadingList,
  //       paymentLoadingList,
  //       authLoadingList,
  //       jobsLoadingList,
  //     },
  //     null,
  //     2
  //   )
  // );

  return (
    <div className="w-full h-full">
      {isMessageModalOpen && (
        <AppMessageModal
          open={isMessageModalOpen}
          type={
            messageModalDetails && messageModalDetails.modalType
              ? messageModalDetails.modalType
              : 'SUCCESS'
          }
          title={messageModalDetails && messageModalDetails.title ? messageModalDetails.title : ''}
          subTitle={
            messageModalDetails && messageModalDetails.subTitle ? messageModalDetails.subTitle : ''
          }
          buttonText={
            messageModalDetails && messageModalDetails.buttonText
              ? messageModalDetails.buttonText
              : ''
          }
          secondButtonText={
            messageModalDetails && messageModalDetails.secondButtonText
              ? messageModalDetails.secondButtonText
              : ''
          }
          isSecondButton={messageModalDetails && messageModalDetails.isSecondButton ? true : false}
          onSecondButtonClick={() => {
            if (messageModalDetails && messageModalDetails.onSecondButtonClick !== undefined) {
              messageModalDetails.onSecondButtonClick();
              dispatch(closeMessageModal());
            }
          }}
          handleClose={() => {
            if (messageModalDetails && messageModalDetails.onClose) {
              messageModalDetails.onClose();
            }
            dispatch(closeMessageModal());
          }}
          handleCloseClickOutsideModal={() => {
            if (messageModalDetails && messageModalDetails.closeOnClickOutsideModal) {
              dispatch(closeMessageModal());
            }
          }}
          customIcon={
            messageModalDetails && messageModalDetails.customIcon
              ? messageModalDetails.customIcon
              : undefined
          }
          customIconContainerStyle={
            messageModalDetails && messageModalDetails.customIconContainerStyle
              ? messageModalDetails.customIconContainerStyle
              : undefined
          }
        />
      )}
      {firebaseUserId !== null &&
        (invoiceLoadingList.length > 0 ||
          buyerLoadingList.length > 0 ||
          salesReceiptsLoadingList.length > 0 ||
          liensLoadingList.length > 0 ||
          supplierLoadingList.length > 0 ||
          preliminaryNoticesLoadingList.length > 0 ||
          noticeTemplatesLoadingList.length > 0 ||
          supplierJobsLoadingList.filter((item) => item === 'getSupplierJobs').length > 0 ||
          supplierBuyersLoadingList.filter((item) => item === 'getSupplierBuyers').length > 0 ||
          jobsLoadingList.length > 0 ||
          lienTemplatesLoadingList.length > 0 ||
          subSuppliersLoadingList.length > 0 ||
          manualUploadLoadingList.length > 0 ||
          paymentLoadingList.filter(
            (item) =>
              item === 'getSupplierTransactions' ||
              item === 'getBuyerTransactions' ||
              item === 'createExternalPaymentMethod' ||
              item === 'verifyDwollaMicroTransactions' ||
              item === 'getTransactionById' ||
              item === 'getMainBuyerPaymentMethods' ||
              item === 'getSupplierPaymentsFiles' ||
              item === 'getBuyerTransactionsFiles' ||
              item === 'sendInvoicePaymentReminderEmail' ||
              // item === 'getWeekPaymentTransactionsLimitations' ||
              // item === 'getSupplierRegistrationStatus' ||
              item === 'createSupplierPaymentMethod'
          ).length > 0 ||
          authLoadingList.length > 0) && <AppProgressSpinner />}
      {stripePublishableKey && stripePromise && firebaseUserId !== null && (
        <Elements stripe={stripePromise}>
          <AppRouter />
        </Elements>
      )}
    </div>
  );
}

export default AppSetup;
